/* eslint-disable @angular-eslint/directive-class-suffix */
import {ChangeDetectorRef, Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {CarsAction} from 'app/permissions/types/permissions';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {CopyPasteService} from 'app/services/copy-paste/copy-paste.service';
import {UserService} from 'app/services/user/user.service';
import {User} from 'app/user/user';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {FragmentService} from '../../services/fragment.service';
import {LockService} from '../../services/lock.service';
import {ClauseComponent} from '../clause/clause.component';
import {hasDescendantOfType} from '../fragment-utils';
import {Lock} from '../lock/lock';
import {
  CaptionedFragment,
  ClauseFragment,
  ClauseType,
  Fragment,
  FragmentType,
  SectionFragment,
  SectionType,
  TextFragment,
} from '../types';
import {ClauseGroupFragment} from '../types/clause-group-fragment';
import {FragmentComponent} from './fragment.component';

@Directive()
export class CaptionedFragmentComponent extends FragmentComponent implements OnInit, OnDestroy {
  public readonly ClauseType: typeof ClauseType = ClauseType;
  public readonly CarsAction: typeof CarsAction = CarsAction;

  public clause: ClauseFragment;
  public section: SectionFragment;

  public helpUrl: string;

  public userLockingClause: User;

  protected _subscriptions: Subscription[] = [];

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _fragmentService: FragmentService,
    protected _lockService: LockService,
    protected _copyPasteService: CopyPasteService,
    protected _userService: UserService,
    protected _selectionOperationService: SelectionOperationsService,
    protected elementRef: ElementRef
  ) {
    super(_cd, elementRef);
  }

  /**
   * Initialise this component and set parent clause and section.
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.clause = this.content.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    this.section = this.clause.findAncestorWithType(FragmentType.SECTION) as SectionFragment;

    this._subscriptions.push(
      this._lockService.onChange(
        (lock: Lock) => {
          if (!this._lockService.canLock(this.clause)) {
            this._userService
              .getUserFromId(lock.userId)
              .pipe(take(1))
              .subscribe((user: User) => {
                this.userLockingClause = user;
                this._cd.markForCheck();
              });
          } else {
            this.userLockingClause = null;
            this._cd.markForCheck();
          }
        },
        (lock: Lock) => lock && this.clause && lock.clauseId.equals(this.clause.id)
      )
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Needed to keep captioned fragments indexes in sync.
   *
   * @override
   */
  public markForCheck(): void {
    super.markForCheck();
    const caption: TextFragment = (this.content as CaptionedFragment).caption;
    if (caption) {
      caption.markForCheck();
    }
  }

  /**
   * Determine whether the caption should show given the parent clause and section types.
   *
   * @returns {boolean}   True if should show
   */
  public showIndex(): boolean {
    return (
      !!this.clause &&
      !!this.section &&
      (this.clause.clauseType === ClauseType.REQUIREMENT ||
        this.clause.clauseType === ClauseType.ADVICE ||
        this.clause.clauseType === ClauseType.NOTE ||
        this.section.sectionType === SectionType.APPENDIX)
    );
  }

  /**
   * Respond to a delete event for this fragment.
   */
  public onDelete(): void {}

  /**
   * Respond to a landscape event for this fragment.
   */
  public onLandscape(): void {}

  /**
   * Respond to a replace event for this fragment.
   */
  public onReplace(event: Event): void {}

  /**
   * Respond to a toggle variable table event for this fragment.
   */
  public onToggleVariableTable(): void {}

  /**
   * Respond to a toggle header row event for this fragment.
   */
  public onToggleHeaderRow(): void {}

  /**
   * Respond to a copy event for this fragment.
   */
  public onCopy(): void {
    const copyListener = ((event: ClipboardEvent) => {
      const plainText: string = this.elementRef.nativeElement.innerText.split('\n')[0];
      const htmlText: string = this._getHtmlText();
      this._copyPasteService.copy(event, this.content, plainText, htmlText);
    }).bind(this);

    document.addEventListener('copy', copyListener, true);
    document.execCommand('copy');
    document.removeEventListener('copy', copyListener, true);
  }

  private _getHtmlText(): string {
    const clonedNativeElement: Element = this.elementRef.nativeElement.cloneNode(true);
    this._clearUnwantedNodes(clonedNativeElement);
    return clonedNativeElement.outerHTML;
  }

  private _clearUnwantedNodes(node: Element): void {
    if (node.nodeName === 'BUTTON' || node.nodeName === 'LABEL') {
      node.remove();
    } else if (node.hasChildNodes()) {
      for (let i = 0; i < node.childNodes.length; i++) {
        this._clearUnwantedNodes(node.childNodes[i] as Element);
      }
    }
  }

  /**
   * Moves a captioned fragment the given number of indexes in a clause.
   * If unable to move within the clause, will move to the next/previous clause.
   *
   * @param indexes {number} Number of indexes to move fragment. Pass negative values for moving backwards
   */
  public onMove(indexes: number): void {
    const clauseChildren: Fragment[] = this.clause.children;
    let newIndex: number = this.content.index() + indexes;

    // skip over anchor fragments
    while (!!clauseChildren[newIndex] && clauseChildren[newIndex].is(FragmentType.ANCHOR)) {
      newIndex += indexes / Math.abs(indexes);
    }

    !!clauseChildren[newIndex] && clauseChildren[newIndex].isCaptioned()
      ? this._moveWithinClause(newIndex)
      : this._moveToClause(indexes);
  }

  /**
   * Moves this fragment to a new position in its parents array.
   *
   * @param index {number} Index to move to
   */
  private _moveWithinClause(index: number): void {
    if (this._lockService.canLock(this.clause)) {
      this.content.remove();
      this.clause.children.splice(index, 0, this.content);
      this._fragmentService.update(this.content);
    }
  }

  /**
   * Moves this fragment to the next/previous clause.
   *
   * @param indexes {number} Direction to move captioned fragment
   */
  private _moveToClause(indexes: number): void {
    const newParentClause: ClauseFragment = this.findNextClauseToMoveTo(indexes);

    if (!!newParentClause && this._lockService.canLock(newParentClause)) {
      this.content.remove();

      let indexToInsert: number =
        indexes > 0 ? newParentClause.children.findIndex((f: Fragment) => f.isCaptioned()) : -1;
      indexToInsert = indexToInsert >= 0 ? indexToInsert : newParentClause.children.length;

      newParentClause.children.splice(indexToInsert, 0, this.content);
      this._fragmentService.update(this.content).then(() => this._lockService.unlock(this.clause));
    }
  }

  /**
   * Returns the next clause that can contain a captioned fragment in the given direction, or null if none exist.
   * Clause groups and specifier instruction clauses cannot contain captioned fragments.
   *
   * @param indexes {number}  Number of indexes to move the fragment. Pass negative for moving backwards.
   * @return {Fragment}
   */
  public findNextClauseToMoveTo(indexes: number): ClauseFragment {
    const next: boolean = indexes > 0;
    let sibling: Fragment = this._getNextModifiableSibling(this.clause, next);
    let clauseGroupAncestor: Fragment = this.clause.findAncestorWithType(FragmentType.CLAUSE_GROUP);

    while (!sibling && !!clauseGroupAncestor) {
      sibling = this._getNextModifiableSibling(clauseGroupAncestor, next);
      clauseGroupAncestor = clauseGroupAncestor.parent?.findAncestorWithType(FragmentType.CLAUSE_GROUP);
    }

    if (!!sibling && sibling.is(FragmentType.CLAUSE_GROUP)) {
      sibling = this._getFirstOrLastModifiableChild(sibling, next);
    }

    return sibling as ClauseFragment;
  }

  /**
   * Iterates through fragment siblings searching for the next (or previous) appropriate clause or clause group
   * @param fragment {Fragment} fragment to find sibling of
   * @param next {boolean} whether to find next (true) or previous (false)
   * @return {Fragment} ClauseFragment or ClauseGroupFragment of appropriate sibling
   */
  private _getNextModifiableSibling(fragment: Fragment, next: boolean): Fragment {
    let sibling: Fragment = next ? fragment.nextSibling() : fragment.previousSibling();

    while (
      sibling &&
      (this._isFragmentClauseGroupAndContentNotTable(sibling) ||
        this._fragmentInClauseGroupContainsTableAndContentTable(sibling) ||
        sibling.isClauseOfType(ClauseType.SPECIFIER_INSTRUCTION) ||
        this._isFragmentUnmodifiableClause(sibling))
    ) {
      sibling = next ? sibling.nextSibling() : sibling.previousSibling();
    }

    return sibling;
  }

  /**
   * Returns false (can move to) if fragment is a clause group that has a clause without a table or if the captioned
   * fragment is not a table (eg figure) and fragment is a clause group
   * @param fragment fragment to potentially move to
   */
  private _isFragmentClauseGroupAndContentNotTable(fragment: Fragment): boolean {
    const isClauseGroup: boolean = fragment.is(FragmentType.CLAUSE_GROUP);
    const contentTable: boolean = this._content.is(FragmentType.TABLE);
    const childWithoutTable: boolean = this._clauseGroupHasChildWithoutTable(fragment as ClauseGroupFragment);

    return isClauseGroup && (!contentTable || !childWithoutTable);
  }

  /**
   * Checks clause group children for any modifiable clauses without children, as well as checking children of any
   * nested clause groups
   * @param clauseGroup parent clause group to find child of
   */
  private _clauseGroupHasChildWithoutTable(clauseGroup: Fragment): boolean {
    if (!clauseGroup.is(FragmentType.CLAUSE_GROUP)) {
      return true;
    }

    const fragmentIsModifiableClauseWithoutTableChild = (fragment: Fragment) =>
      fragment.is(FragmentType.CLAUSE) &&
      !this._isFragmentUnmodifiableClause(fragment) &&
      !hasDescendantOfType(fragment, 1, FragmentType.TABLE);

    const hasChildWithoutTable = (fragment: Fragment): boolean => {
      if (fragmentIsModifiableClauseWithoutTableChild(fragment)) {
        return true;
      }

      if (!fragment.is(FragmentType.CLAUSE_GROUP)) {
        return false;
      }

      return fragment.children.some(hasChildWithoutTable);
    };

    return hasChildWithoutTable(clauseGroup);
  }

  /**
   * Returns true (skip over) if the current fragment is within a clause group and contains a table already
   * when moving a table fragment
   * @param fragment fragment to potentially move to
   * @private
   */
  private _fragmentInClauseGroupContainsTableAndContentTable(fragment: Fragment): boolean {
    const containsTable: boolean = hasDescendantOfType(fragment, 1, FragmentType.TABLE);
    const contentIsTable: boolean = this.content.is(FragmentType.TABLE);
    const clauseGroupParent: boolean = !!fragment.findAncestorWithType(FragmentType.CLAUSE_GROUP);
    return containsTable && contentIsTable && clauseGroupParent;
  }

  /**
   * Recursively finds the appropriate child clause of a clause group. Recursion is used for nested fragments.
   *
   * @param fragment {Fragment} current clause group to find child of
   * @param first {boolean} whether to find the first or last modifiable child
   * @return {Fragment} modifiable child fragment of given clause group
   */
  private _getFirstOrLastModifiableChild(fragment: Fragment, first: boolean): Fragment {
    if (!fragment || !fragment.is(FragmentType.CLAUSE_GROUP)) {
      return fragment;
    }

    let firstOrLastChild: Fragment = first ? fragment.children[0] : fragment.children[fragment.children.length - 1];

    if (
      this._isFragmentUnmodifiableClause(firstOrLastChild) ||
      !this._clauseGroupHasChildWithoutTable(firstOrLastChild) ||
      this._fragmentInClauseGroupContainsTableAndContentTable(firstOrLastChild)
    ) {
      firstOrLastChild = this._getNextModifiableSibling(firstOrLastChild, first);
    }

    return this._getFirstOrLastModifiableChild(firstOrLastChild, first);
  }

  /**
   * Selects the caption for the fragment.
   *
   * @param selectStart {boolean}  Whether to select the start of the caption if true, or the end if false.
   */
  public selectCaption(selectStart: boolean): void {
    const caption: TextFragment = (this.content as CaptionedFragment).caption;

    if (!!this.clause?.component) {
      this._selectionOperationService.setSelected(
        caption,
        selectStart ? 0 : caption.length(),
        (this.clause?.component as ClauseComponent).padType
      );
    }
  }

  /**
   * Returns whether the ancestor clause is unmodifiable and should be skipped over
   *
   * @param fragment {Fragment}  the current sibling being considered to move to.
   */
  private _isFragmentUnmodifiableClause(fragment: Fragment): boolean {
    if (!fragment.is(FragmentType.CLAUSE)) {
      return false;
    }

    const clause: ClauseFragment = fragment as ClauseFragment;
    return clause.isUnmodifiableClause;
  }
}
