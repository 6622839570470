/* eslint-disable brace-style */
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PadType} from 'app/element-ref.service';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {TableCellFragment, TableRowFragment} from 'app/fragment/table/table-fragment';
import {Fragment} from 'app/fragment/types';
import {SelectionOperationsService} from 'app/selection-operations.service';
import {CopyPasteService} from 'app/services/copy-paste/copy-paste.service';
import {DomService} from 'app/services/dom.service';
import {FragmentDeletionValidationService} from 'app/services/fragment-deletion-validation.service';
import {FragmentService} from 'app/services/fragment.service';
import {LockService} from 'app/services/lock.service';
import {ReorderClausesService} from 'app/services/reorder-clauses.service';
import {RichTextService} from 'app/services/rich-text.service';
import {UserService} from 'app/services/user/user.service';
import {Browser} from 'app/utils/browser';
import {ViewMode} from 'app/view/current-view';
import {UUID} from '../../utils/uuid';
import {ActionRequest} from '../action-request';
import {Caret} from '../caret';
import {ClauseComponent} from '../clause/clause.component';
import {CaptionedFragmentComponent} from '../core/captioned-fragment.component';
import {FragmentComponent} from '../core/fragment.component';
import {EquationFragmentComponent} from '../equation/equation-fragment.component';
import {Key} from '../key';
import {FragmentType, ListFragment, SectionFragment, SectionType} from '../types';
import {VersioningService} from '../versioning/versioning.service';
import {FragmentUpdates, PreviewType, SplitDirection, TableFragment, TextAlignment} from './table-fragment';
import {Point, TableMenu} from './table-menu';

@Component({
  selector: 'cars-table-fragment',
  templateUrl: 'table-fragment.component.html',
  styleUrls: ['../core/fragment.component.scss', './table-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true,
})
export class TableFragmentComponent
  extends CaptionedFragmentComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @Input() public index: number;

  @ViewChild(MatMenuTrigger) public cellMenuTrigger: MatMenuTrigger;
  @ViewChild(MatMenu) public cellMenu: MatMenu;
  @ViewChild('previewBox') public preview: ElementRef;
  @ViewChild('tableBody') public tableBody: ElementRef;
  @ViewChild('captionBox') public captionBox: ElementRef;

  public readonly PadType: typeof PadType = PadType;
  public readonly TextAlignment: any = TextAlignment;
  public readonly SectionType: any = SectionType;
  public readonly SplitDirection: any = SplitDirection;
  public readonly PreviewType: any = PreviewType;
  public readonly RowHeight: number = 16; // 16px height
  public readonly id: string = `cars-table-fragment-${UUID.random().value}`;
  public readonly firefox: boolean = Browser.isFirefox();
  public showMarginIcons: boolean = false;
  public padType: PadType = PadType.MAIN_EDITABLE;
  public getUserColour: Function = UserService.getUserColour;
  public variableTable: boolean;
  public showBorders: boolean;
  public section: SectionFragment;
  public menu: TableMenu = new TableMenu(145); // 145px width
  public dragStart: Point = null;
  public dragEnd: Point = null;
  public dragSelecting: boolean = false;
  public reordering: boolean = false;

  private _rendered: boolean = false;

  @Input() public set content(value: TableFragment) {
    super.content = value;
  }

  public get content(): TableFragment {
    return super.content as TableFragment;
  }

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _fragmentService: FragmentService,
    protected _lockService: LockService,
    private _domService: DomService,
    private _reorderService: ReorderClausesService,
    private _richTextService: RichTextService,
    protected _copyPasteService: CopyPasteService,
    protected _userService: UserService,
    private _versioningService: VersioningService,
    private _fragmentDeletionValidationService: FragmentDeletionValidationService,
    private _snackbar: MatSnackBar,
    protected _selectionOperationService: SelectionOperationsService,
    elementRef: ElementRef
  ) {
    super(_cd, _fragmentService, _lockService, _copyPasteService, _userService, _selectionOperationService, elementRef);
  }

  /**
   * Initialise this table component by finding the parent clause.
   */
  public ngOnInit(): void {
    super.ngOnInit();

    this.variableTable = !!this.content.findAncestorWithType(FragmentType.EQUATION);
    if (this.variableTable) {
      const equationComponent = this.content.findAncestorWithType(FragmentType.EQUATION)
        .component as EquationFragmentComponent;
      this._subscriptions.push(
        equationComponent.getFocusObservable().subscribe((isFocused) => (this.showBorders = isFocused))
      );
    } else {
      this.showBorders = false;
    }

    this.content.updateHeaderCells();
    this.handleMinWidths();

    const clauseComponent: ClauseComponent = this.clause?.component;
    if (!!clauseComponent) {
      this.padType = clauseComponent.padType;
      this.showMarginIcons = clauseComponent.showMarginIcons;

      if (!!clauseComponent.currentView) {
        // If this table is being displayed in a clause change summary screen, landscape should always be false
        // in order to prevent issues with displaying all table columns
        this.content.landscape =
          clauseComponent.currentView.viewMode !== ViewMode.CHANGE_SUMMARY && this.content.landscape;
      }
    }
  }

  /**
   * Initialise the table menu's element.
   */
  public ngAfterViewInit(): void {
    this.menu.close();

    this._subscriptions.push(
      this._reorderService.onReorderingEvent().subscribe((value: boolean) => {
        this.reordering = value;
      })
    );

    super.ngAfterViewInit();
  }

  /**
   * Disable native controls (drag/resize handles). This is done here rather than in ngAfterViewInit because the table element hasn't
   * actually rendered at that point (it exists in Angular's virtual DOM, but won't show up using a document.getElement method).
   */
  public ngAfterViewChecked(): void {
    if (document.getElementById(this.id) && !this._rendered) {
      this._domService.disableNativeEditors();
      this._rendered = true;
    }
  }

  /**
   * Handle an editor keydown event.
   *
   * @param key    {Key}                 The pressed key
   * @param target {FragmentComponent}   The target FragmentComponent
   * @param caret  {Caret}               The caret position
   * @returns      {ActionRequest}       The requested action
   */
  public onKeydown(key: Key, target: FragmentComponent, caret: Caret): ActionRequest {
    let action: ActionRequest = new ActionRequest();
    const index: number = this.content.childIndexOf(target.content);

    if (key.equalsUnmodified(Key.TAB) && index >= 0) {
      if (key.shift && !this.content.children[index].isFirstChild()) {
        action.fragment = this.content.children[index].previousLeaf();
        action.offset = Math.max(action.fragment.length(), Math.min(caret.offset, 0));
      } else if (!key.shift && !this.content.children[index].isLastChild()) {
        action.fragment = this.content.children[index].nextLeaf();
        action.offset = Math.max(action.fragment.length(), Math.min(caret.offset, 0));
      } else {
        // Do nothing -- don't let tab bubble past a table
      }
    } else if (key.equalsUnmodified(Key.ESCAPE)) {
      this.blur();
    } else {
      action = null;
    }

    return action;
  }

  /**
   * Respond to a mousedown event on a cell by starting the drag event.
   *
   * @param row   {number}       The row index
   * @param col   {number}       The col index
   * @param event {MouseEvent}   The mouse event
   */
  public onCellMousedown(row: number, col: number, event: MouseEvent): void {
    this.menu.close();
    if (!this.reordering && this.clause.isAttached() && !this.userLockingClause && !this.readOnly) {
      this.dragStart = this.dragEnd = new Point(row, col);
      this.dragSelecting = true;
      // Attach listener to document to allow mouseup from anywhere, rather than just over table
      const callback: (MouseEvent) => void = (mouseupEvent: MouseEvent) => {
        this.onCellMouseup();
        this.markForCheck();
        document.removeEventListener('mouseup', callback);
      };
      document.addEventListener('mouseup', callback);
    }
  }

  /**
   * Respond to a cell mouseenter event by updating the selected region if in the
   * process of drag-selecting cells.
   *
   * @param row   {number}       The row index
   * @param col   {number}       The column index
   * @param event {MouseEvent}   The mouse event
   */
  public onCellEnter(row: number, col: number): void {
    if (!this.reordering && this.dragStart && this.dragSelecting) {
      this.dragEnd = new Point(row, col);

      const bounds: Point[] = this._normaliseDragBounds(this.dragStart, this.dragEnd);

      for (let i: number = 0; i < this.content.children.length; ++i) {
        for (let j: number = 0; j < this.content.children[i].children.length; ++j) {
          const point: Point = new Point(i, j);
          this.menu.selection[i] = this.menu.selection[i] || [];
          this.menu.selection[i][j] = point.containedWithin(bounds[0], bounds[1]);
        }
      }
    }
  }

  /**
   * Respond to a cell mouseup event by setting mergeMode if there is a selection.
   */
  public onCellMouseup(): void {
    this.dragSelecting = false;
    this.menu.mergeMode = this.dragStart && this.dragEnd && Point.areaBetween(this.dragStart, this.dragEnd) > 1;
  }

  /**
   * Respond to a click on the cell menu button, by setting selection and menu open position.
   *
   * @param row   {number}       The row index
   * @param col   {number}       The column index
   * @param event {MouseEvent}   The mouse event
   */
  public onMenuClick(row: number, col: number, event: MouseEvent): void {
    if (!this.dragSelecting) {
      event.stopPropagation();
      if (!this.menu.mergeMode) {
        for (let i: number = 0; i < this.content.children.length; ++i) {
          this.menu.selection[i] = [];
        }
        this.menu.selection[row][col] = true;
      }
      this.menu.open(row, col);
      this._lockService.lock(this.clause, true);
    }
  }

  /**
   * Specialise the fragment blur action by closing the menu.
   */
  public blur(): void {
    // This is to get rid of the ExpressionChangedAfterItHasBeenCheckedError that gets thrown when clicking out of a table.
    setTimeout(() => {
      this.menu.close();
      this.dragStart = null;
    });
  }

  /**
   * Returns whether a cell is the top right in a selection
   *
   * @param row   {number}   The row index
   * @param col   {number}   The column index
   * @returns     {Boolean}  True if cell is top right in selection
   */
  public isTopRightCell(row: number, col: number): boolean {
    if (this.dragStart && this.dragEnd && this.menu.mergeMode) {
      const bounds: Point[] = this._normaliseDragBounds(this.dragStart, this.dragEnd);
      let topRight: TableCellFragment = this.content.at(bounds[0].row, bounds[1].col);
      while (topRight.deleted) {
        topRight = topRight.previousSibling() as TableCellFragment;
      }
      if (row === topRight.parent.index() && col === topRight.index()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Merge the selected cells, if the selection is a rectangular region.
   *
   * @param event {MouseEvent}   The mouse event
   */
  public mergeCells(event: MouseEvent): void {
    this.cellMenuTrigger.closeMenu();
    event.stopPropagation();
    if (this.dragStart && this.dragEnd) {
      const bounds: Point[] = this._normaliseDragBounds(this.dragStart, this.dragEnd);

      try {
        const fragmentsUpdates: FragmentUpdates = this.content.mergeCells(
          bounds[0].row,
          bounds[0].col,
          bounds[1].row,
          bounds[1].col
        );

        this.processFragmentUpdates(fragmentsUpdates);
        this.blur();
      } catch (error) {
        Logger.error('table-layout-error', error.message, error);
        this._snackbar.open(error.message, 'Dismiss', {duration: 3000});
      }
    }

    this.menu.close();
    this.dragStart = null;
    this.dragEnd = null;
  }

  /**
   * Insert a row into the table
   *
   * @param event {MouseEvent}   The menu mouse event
   * @param above {boolean}      Insert above, default to false i.e inserting below
   */
  public insertRow(event: MouseEvent, above: boolean = false): void {
    if (this.menu.isOpen()) {
      event.stopPropagation();
      this.cellMenuTrigger.closeMenu();

      const fragmentUpdates: FragmentUpdates = this.content.insertRow(this.menu.row, above);
      this.processFragmentUpdates(fragmentUpdates);
      this.blur();
    }
  }

  /**
   * Insert a column into the table
   *
   * @param event {MouseEvent}   The menu mouse event
   * @param before {boolean}     Insert before, default to false i.e inserting after
   */
  public insertColumn(event: MouseEvent, before: boolean = false): void {
    if (this.menu.isOpen()) {
      event.stopPropagation();
      this.cellMenuTrigger.closeMenu();
      const fragmentUpdates: FragmentUpdates = this.content.insertColumn(this.menu.col, before);

      this.processFragmentUpdates(fragmentUpdates);
      this.blur();
    }
  }

  /**
   * Process the changes of the a fragment
   * @param fragmentUpdates The updates to the fragment
   */
  private processFragmentUpdates(fragmentUpdates: FragmentUpdates): Promise<void> {
    const promises: Promise<void>[] = [];
    if (fragmentUpdates) {
      if (fragmentUpdates.fragmentsCreated && fragmentUpdates.fragmentsCreated.length) {
        promises.push(this._fragmentService.create(fragmentUpdates.fragmentsCreated).then(() => {}));
      }
      if (fragmentUpdates.fragmentsUpdated && fragmentUpdates.fragmentsUpdated.length) {
        promises.push(this._fragmentService.update(fragmentUpdates.fragmentsUpdated).then(() => {}));
      }
      if (fragmentUpdates.fragmentsDeleted && fragmentUpdates.fragmentsDeleted.length) {
        // Validated in the methods that call this method
        promises.push(this._fragmentService.delete(fragmentUpdates.fragmentsDeleted).then(() => {}));
      }
    }

    return Promise.all(promises).then(() => {});
  }

  /**
   * Delete the row containing the cell at (this.menu.row, this.menu.col).
   *
   * @param event {MouseEvent}   The menu mouse event
   */
  public async deleteRow(event: MouseEvent): Promise<void> {
    if (this.menu.isOpen()) {
      event.stopPropagation();
      this.stylePreviewBox(PreviewType.NONE);
      const rowSpan: number = this.content.at(this.menu.row, this.menu.col).rowSpan;

      if (this.content.numRows() === rowSpan) {
        this.deleteTable();
      } else {
        const rowsToBeDeleted: TableRowFragment[] = this.content.children.slice(this.menu.row, this.menu.row + rowSpan);

        if (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees(rowsToBeDeleted)) {
          for (let i: number = 0; i < rowSpan; ++i) {
            const updates: FragmentUpdates = this.content.deleteRow(this.menu.row);
            this.processFragmentUpdates(updates);
          }
        }
      }
      this.menu.close();
    }
  }

  /**
   * Delete the column containing the cell at (this.menu.row, this.menu.col).
   *
   * @param event {MouseEvent}   The menu mouse event
   */
  public async deleteColumn(event: MouseEvent): Promise<void> {
    if (this.menu.isOpen()) {
      event.stopPropagation();
      this.stylePreviewBox(PreviewType.NONE);
      const colSpan: number = this.content.at(this.menu.row, this.menu.col).colSpan;

      if (this.content.numColumns() === colSpan) {
        this.deleteTable();
      } else {
        const cellsToBeDeleted: TableCellFragment[] = [];
        this.content.children.forEach((row: TableRowFragment) => {
          cellsToBeDeleted.push(...row.children.slice(this.menu.col, this.menu.col + colSpan));
        });
        if (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees(cellsToBeDeleted)) {
          for (let i: number = 0; i < colSpan; ++i) {
            const updates: FragmentUpdates = this.content.deleteColumn(this.menu.col);
            this.processFragmentUpdates(updates);
          }
        }
      }
      this.menu.close();
    }
  }

  /**
   * Split a cell with rowSpan or colSpan > 1.  The cell at (this.menu.row, this.menu.col)
   * has its colSpan or rowSpan set to one afterward, with new cells inserted afterwards.
   *
   * @param event     {MouseEvent}     The menu mouse event
   * @param direction {SplitDirection} The direction to split the cell
   */
  public splitCell(event: MouseEvent, direction: SplitDirection): void {
    if (this.menu.isOpen()) {
      this.cellMenuTrigger.closeMenu();
      event.stopPropagation();
      this.stylePreviewBox(PreviewType.NONE);
      const fragmentUpdates: FragmentUpdates = this.content.splitCell(this.menu.row, this.menu.col, direction);

      this.processFragmentUpdates(fragmentUpdates);
      this.blur();
      this.menu.close();
    }
  }

  /**
   * @inheritdoc
   */
  public onDelete(): void {
    this.deleteTable();
  }

  /**
   * Delete this table by detaching it from the fragment tree.
   */
  public async deleteTable(): Promise<void> {
    if (await this._fragmentDeletionValidationService.shouldDeleteFragmentsWithSubtrees(this.content)) {
      this._fragmentService.delete(this.content);
    }
  }

  /**
   * Toggle headings for the region defined by the selection.
   *
   * @param event {MouseEvent} The mouse event
   */
  public toggleBold(event: MouseEvent): void {
    event.stopPropagation();
    this.cellMenuTrigger.closeMenu();

    let fragmentUpdates: FragmentUpdates = null;
    if (this.dragStart && this.dragEnd) {
      const bounds: Point[] = this._normaliseDragBounds(this.dragStart, this.dragEnd);
      fragmentUpdates = this.content.toggleBold(bounds[0].row, bounds[0].col, bounds[1].row, bounds[1].col);
    } else {
      fragmentUpdates = this.content.toggleBold(this.menu.row, this.menu.col, this.menu.row, this.menu.col);
    }
    this.processFragmentUpdates(fragmentUpdates);
    this.blur();
  }

  /**
   * @inheritdoc
   */
  public onLandscape(): void {
    this.content.toggleLandscape();
    this._richTextService.triggerLandscapeEvent();
    this._fragmentService.update(this.content);
    this.dragStart = null;
  }

  /**
   * @inheritDoc
   */
  public onToggleHeaderRow(): void {
    try {
      this.content.toggleHasHeaderRow();
    } catch (error) {
      Logger.error('table-layout-error', error.message, error);
      this._snackbar.open(error.message, 'Dismiss', {duration: 3000});
    }

    this._fragmentService.update(this.content);
  }

  /**
   * Normalise two Points to properly order them.  The resulting array has first element with
   * top-left less than the second point, which is expanded to fill its row/column span.
   *
   * @param first  {Point}     The first point
   * @param second {Point}     The second point
   * @returns      {Point[]}   The resulting array
   */
  private _normaliseDragBounds(first: Point, second: Point): Point[] {
    // Ensure that dragStart and dragEnd define a rectangle with origin at the top-left
    const start: Point = new Point(Math.min(first.row, second.row), Math.min(first.col, second.col));
    const end: Point = new Point(Math.max(first.row, second.row), Math.max(first.col, second.col));

    // If end is within a row/col span, expand it to be anchored at the end of the span
    end.row = Math.min(end.row + this.content.at(end.row, end.col).rowSpan - 1, this.content.children.length - 1);
    end.col = Math.min(
      end.col + this.content.at(end.row, end.col).colSpan - 1,
      this.content.children[end.row].children.length - 1
    );

    return [start, end];
  }

  /**
   * Sets the text alignment of the cell/cells
   *
   * @param alignment {TextAlignment} The text alignment of the cell(s)
   */
  public justify(alignment: TextAlignment, event: MouseEvent): void {
    let fragmentUpdates: FragmentUpdates = null;

    event.stopPropagation();
    this.cellMenuTrigger.closeMenu();
    if (this.dragStart && this.dragEnd) {
      const bounds: Point[] = this._normaliseDragBounds(this.dragStart, this.dragEnd);
      fragmentUpdates = this.content.setAlignment(
        bounds[0].row,
        bounds[0].col,
        bounds[1].row,
        bounds[1].col,
        alignment
      );
    } else {
      fragmentUpdates = this.content.setAlignment(
        this.menu.row,
        this.menu.col,
        this.menu.row,
        this.menu.col,
        alignment
      );
    }
    this.processFragmentUpdates(fragmentUpdates);
    this.blur();
  }

  /**
   * Set the style properties of the preview box element
   *
   * @param type  {PreviewType}   The preview type
   */
  public stylePreviewBox(type: PreviewType): void {
    const boxStyle: any = this.preview.nativeElement.style;

    if (type === PreviewType.NONE) {
      boxStyle.display = 'none';
    } else {
      boxStyle.display = 'block';
      this._setPreviewOffset(type);
      this._setPreviewDimensions(type);
      if (type === PreviewType.DELETE_COL || type === PreviewType.DELETE_ROW) {
        boxStyle.backgroundColor = 'rgba(200, 0, 0, 0.4)';
      } else {
        boxStyle.backgroundColor = 'royalblue';
      }
    }
  }

  /**
   * Set the offset of the preview box relative to the table body
   *
   * @param type  {PreviewType}   The preview type
   */
  private _setPreviewOffset(type: PreviewType): void {
    const cellRect: DOMRect = this.content.at(this.menu.row, this.menu.col).component.element.getBoundingClientRect();
    const tableRect: DOMRect = this.tableBody.nativeElement.getBoundingClientRect();
    const captionRect: DOMRect = this.captionBox ? this.captionBox.nativeElement.getBoundingClientRect() : null;
    let top: number;
    let left: number;

    switch (type) {
      case PreviewType.ROW_ABOVE:
      case PreviewType.DELETE_ROW:
        {
          left = 0;
          top = cellRect.top - tableRect.top;
        }
        break;

      case PreviewType.ROW_BELOW:
        {
          left = 0;
          top = cellRect.bottom - tableRect.top;
        }
        break;

      case PreviewType.COL_BEFORE:
      case PreviewType.DELETE_COL:
        {
          left = cellRect.left - tableRect.left;
          top = 0;
        }
        break;

      case PreviewType.COL_AFTER:
        {
          left = cellRect.right - tableRect.left;
          top = 0;
        }
        break;

      case PreviewType.SPLIT_VERTICALLY:
        {
          left =
            cellRect.left - tableRect.left + cellRect.width / this.content.at(this.menu.row, this.menu.col).colSpan;
          top = cellRect.top - tableRect.top;
        }
        break;

      case PreviewType.SPLIT_HORIZONTALLY:
        {
          left = cellRect.left - tableRect.left;
          top = cellRect.top - tableRect.top + cellRect.height / this.content.at(this.menu.row, this.menu.col).rowSpan;
        }
        break;
    }

    if (!this.firefox && captionRect) {
      top += captionRect.height;
    }

    this.preview.nativeElement.style.top = `${top}px`;
    this.preview.nativeElement.style.left = `${left}px`;
  }

  /**
   * Set the dimensions of the preview box
   *
   * @param type  {PreviewType}   The preview type
   */
  private _setPreviewDimensions(type: PreviewType): void {
    const cellRect: DOMRect = this.content.at(this.menu.row, this.menu.col).component.element.getBoundingClientRect();
    const tableRect: DOMRect = this.tableBody.nativeElement.getBoundingClientRect();
    let width: number;
    let height: number;

    switch (type) {
      case PreviewType.ROW_ABOVE:
      case PreviewType.ROW_BELOW:
        {
          width = tableRect.width;
          height = 3;
        }
        break;

      case PreviewType.COL_BEFORE:
      case PreviewType.COL_AFTER:
        {
          width = 3;
          height = tableRect.height;
        }
        break;

      case PreviewType.DELETE_ROW:
        {
          width = tableRect.width;
          height = cellRect.height;
        }
        break;

      case PreviewType.DELETE_COL:
        {
          width = cellRect.width;
          height = tableRect.height;
        }
        break;

      case PreviewType.SPLIT_VERTICALLY:
        {
          width = 3;
          height = cellRect.height;
        }
        break;

      case PreviewType.SPLIT_HORIZONTALLY:
        {
          width = cellRect.width;
          height = 3;
        }
        break;
    }

    this.preview.nativeElement.style.width = `${width}px`;
    this.preview.nativeElement.style.height = `${height}px`;
  }

  public columnWidthCalculator(): string[] {
    if (this.content && this.content.children && this.content.children[0] && this.content.children[0].children) {
      return this.content.children[0].children.map((c) => '_');
    } else {
      return [];
    }
  }

  public onCellBlur(): Promise<number> {
    if (!this.content.isScheduleTable) {
      const hiddenRow: Element = this.element.getElementsByClassName('column-width-calculator').item(0);
      const cells: HTMLCollectionOf<HTMLTableCellElement> = hiddenRow.getElementsByTagName('td');

      const columnWidths = [];
      let totalWidth = 0;

      for (let i = 0; i < cells.length; i++) {
        const cellWidth = cells.item(i).offsetWidth;
        columnWidths.push(cellWidth);
        totalWidth += cellWidth;
      }

      const percentageWidths = columnWidths.map((width) => width / totalWidth);
      const savedWidths = this.content.columnWidths;

      if (
        !savedWidths ||
        !(savedWidths.length === percentageWidths.length && savedWidths.every((v, i) => v === percentageWidths[i]))
      ) {
        this.content.columnWidths = percentageWidths;
        return this._versioningService.updateTableWidths(this.content.id, percentageWidths);
      }
    }

    return Promise.resolve(200);
  }

  /**
   * Subscribes to events needed to update whether a cell contains a list.
   * It also updates all cells to see if they contain a list.
   */
  public handleMinWidths(): void {
    this.content.updateIfCellsContainLists();

    this._subscriptions.push(
      this._fragmentService.onDelete(
        (f: ListFragment) => this._updateContainingCell(f),
        (f: Fragment) => f.is(FragmentType.LIST)
      )
    );

    this._subscriptions.push(
      this._fragmentService.onCreate(
        (f: ListFragment) => this._updateContainingCell(f),
        (f: Fragment) => f.is(FragmentType.LIST)
      )
    );
  }
  /**
   * If the given list is in a cell, updates containslist.
   *
   * @param f {ListFragment}   The given list fragment.
   */
  private _updateContainingCell(f: ListFragment): void {
    const cell: TableCellFragment = f.findAncestorWithType(FragmentType.TABLE_CELL) as TableCellFragment;
    if (cell) {
      cell.updateContainsList();
    }
  }
}
